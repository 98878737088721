* {
  @apply antialiased;
}

.display{
  @apply text-display-xl sm:text-display-3xl lg:text-display-4xl xl:text-display-5xl 2xl:text-display-6xl font-medium;
}

.heading-h1{
  @apply text-display-sm sm:text-display-2xl lg:text-display-4xl font-medium;
}

.heading-h2{
  @apply text-display-2xl font-medium;
}

.heading-h3{
  @apply text-display-xl font-medium;
}

.heading-h4{
  @apply text-display-lg font-bold;
}

.label-xl{
  @apply text-base-sm sm:text-xl-sm lg:text-display-md font-medium;
}

.label-lg{
  @apply text-base-sm sm:text-xl-sm lg:text-display-sm font-medium;
}

.label-md{
  @apply text-sm sm:text-base-sm lg:text-display-xs font-medium;
}

.label-sm{
  @apply text-xl-sm font-medium;
}

.label-xs{
  @apply text-base-sm font-medium;
}

.label-xxs{
  @apply text-sm font-medium;
}

.body-xl{
  @apply text-display-xs;
}

.body-lg{
  @apply text-base-md sm:text-lg lg:text-xl-md;
}

.body-md{
  @apply text-sm sm:text-base-md lg:text-lg;
}

.body-sm{
  @apply text-xs sm:text-sm lg:text-base-md;
}

.body-xs{
  @apply text-sm;
}

.body-xxs{
  @apply text-xs font-light;
}

.intense{
  @apply text-xl-md font-bold;
}